import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "syntax-highlighting"
    }}>{`Syntax highlighting`}</h1>
    <p>{`There are two primary approaches for adding syntax highlighting to MDX:`}</p>
    <ul>
      <li parentName="ul">{`composition via the MDXProvider`}</li>
      <li parentName="ul">{`remark plugin`}</li>
    </ul>
    <p>{`It’s typically preferred to take the compositional approach, but both
will be documented here.`}</p>
    <h2 {...{
      "id": "composition"
    }}>{`Composition`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://mdxjs.com/getting-started/#mdxprovider"
      }}>{`MDXProvider`}</a>{` provides
a way to map components to be rendered for a given Markdown element.  So,
this allows you to choose a specific component for the `}<inlineCode parentName="p">{`code`}</inlineCode>{` block.  To
get started you can wrap your app in the MDXProvider and add in a component
to ensure it’s being picked up:`}</p>
    <h3 {...{
      "id": "using-the-mdxprovider"
    }}>{`Using the MDXProvider`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/App.js
import React from 'react'
import {MDXProvider} from '@mdx-js/react'

const components = {
  pre: props => <div {...props} />,
  code: props => <pre style={{ color: 'tomato' }} {...props} />
}

export default props => (
  <MDXProvider components={components}>
    <main {...props}>
    </main>
  </MDXProvider>
)
`}</code></pre>
    <p>{`When you render your app you should now see the color become `}<inlineCode parentName="p">{`tomato`}</inlineCode>{` for
any code block found in your MDX files.`}</p>
    <h3 {...{
      "id": "prism-react-renderer"
    }}>{`prism-react-renderer`}</h3>
    <p>{`Now that you have a custom component being rendered for code blocks you can
choose any React component library to handle the syntax highlighting.  A solid
library to choose is `}<a parentName="p" {...{
        "href": "https://github.com/FormidableLabs/prism-react-renderer"
      }}>{`prism-react-renderer`}</a>{`.`}</p>
    <p>{`You can install it with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn add prism-react-renderer
`}</code></pre>
    <h4 {...{
      "id": "build-a-codeblock-component"
    }}>{`Build a CodeBlock component`}</h4>
    <p>{`You can essentially cut and paste the entire example into a new
component file.  The only big difference is the MDX will pass in the
code string as `}<inlineCode parentName="p">{`children`}</inlineCode>{` so you will need to destructure that prop
and pass it to Highlight as the `}<inlineCode parentName="p">{`code`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/CodeBlock.js
import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'

export default ({children}) => {
  return (
    <Highlight {...defaultProps} code={children} language='javascript'>
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <pre className={className} style={{...style, padding: '20px'}}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({line, key: i})}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({token, key})} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}
`}</code></pre>
    <p>{`Now you should see syntax highlighting in your MDX files.  However, right now
`}<inlineCode parentName="p">{`javascript`}</inlineCode>{` is hardcoded as the language.  You will need to take the language
from the code fence and pass it to Highlight directly.  MDX will pass the language
as `}<inlineCode parentName="p">{`className`}</inlineCode>{` so you can pull out the language with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const language = className.replace(/language-/, '')
`}</code></pre>
    <h3 {...{
      "id": "all-together"
    }}>{`All together`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'

export default ({children, className}) => {
  const language = className.replace(/language-/, '')

  return (
    <Highlight {...defaultProps} code={children} language={language}>
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <pre className={className} style={{...style, padding: '20px'}}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({line, key: i})}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({token, key})} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://github.com/mdx-js/mdx/tree/master/examples/syntax-highlighting"
      }}>{`See the example`}</a></p>
    <h2 {...{
      "id": "remark-plugin"
    }}>{`Remark plugin`}</h2>
    <p>{`In addition to composition you can use any plugin from the remark
ecosystem.  One solid library for syntax highlighting is
`}<a parentName="p" {...{
        "href": "https://github.com/mapbox/rehype-prism"
      }}>{`@mapbox/rehype-prism`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// webpack.config.js
const rehypePrism = require('@mapbox/rehype-prism')

module.exports = {
  module: {
    // ...
    rules: [
      // ...
      {
        test: /.mdx?$/,
        use: [
          'babel-loader',
          {
            resolve: '@mdx-js/loader',
            options: {
              hastPlugins[rehypePrism]
            }
          }
        ]
      }
    ]
  }
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://mdxjs.com/advanced/plugins"
      }}>{`Read more about plugins`}</a></p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      